var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Hidrocarburos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Básico")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Reportes Automáticos")])])])])])]),_c('a',{staticClass:"ir-arriba",on:{"click":function($event){return _vm.irArriba()}}},[_vm._m(1)]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card-body p-2"},[_c('fieldset',{staticClass:"well card"},[_c('legend',{staticClass:"well-legend text-bold bg-frontera text-light"},[_vm._v(" Reportes Automáticos "),_c('button',{staticClass:"btn btn-sm bg-primary float-right m-0",attrs:{"type":"button"},on:{"click":function($event){return _vm.getIndex()}}},[_c('i',{staticClass:"fas fa-sync-alt"})])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row clearfix"},_vm._l((_vm.informes),function(informe,index){return _c('div',{key:index,staticClass:"col-md-3",attrs:{"data-toggle":"modal","data-target":'#modal-form-reporte-guia-export'},on:{"click":function($event){return _vm.getDataModal(informe)}}},[(
                        _vm.$store.getters.can(
                          'hidrocarburos.informeAutomatico.' + informe.permiso
                        )
                      )?_c('div',{staticClass:"small-box text-white",style:({ backgroundColor: _vm.getColor(index) })},[_c('div',{staticClass:"inner"},[_c('p',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(informe.codigo))]),_c('p',{staticStyle:{"font-size":"18px"}},[_vm._v(" V - "+_vm._s(informe.version)+" ")])]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"text-white",class:_vm.getIcon(index)})]),_c('p',{staticClass:"small-box-footer"},[_vm._v(" "+_vm._s(informe.nombre)+" "),_c('i',{staticClass:"fas fa-arrow-circle-right"})])]):_vm._e()])}),0)])])])])])])]),_c('ReporteModal',{ref:"ReporteModal"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fa fa-circle fa-stack-2x"}),_c('i',{staticClass:"fa fa-arrow-up fa-stack-1x fa-inverse"})])
}]

export { render, staticRenderFns }