<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0"></h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Reportes Automáticos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <a class="ir-arriba" @click="irArriba()">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-arrow-up fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <section class="content">
          <div class="container-fluid">
            <div class="card-body p-2">
              <fieldset class="well card">
                <legend class="well-legend text-bold bg-frontera text-light">
                  Reportes Automáticos
                  <button
                    type="button"
                    class="btn btn-sm bg-primary float-right m-0"
                    @click="getIndex()"
                  >
                    <i class="fas fa-sync-alt"></i>
                  </button>
                </legend>
                <div class="card-body">
                  <div class="row clearfix">
                    <div
                      class="col-md-3"
                      v-for="(informe, index) in informes"
                      :key="index"
                      data-toggle="modal"
                      :data-target="'#modal-form-reporte-guia-export'"
                      @click="getDataModal(informe)"
                    >
                      <div
                        class="small-box text-white"
                        :style="{ backgroundColor: getColor(index) }"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.informeAutomatico.' + informe.permiso
                          )
                        "
                      >
                        <div class="inner">
                          <p style="font-size: 18px;">{{ informe.codigo }}</p>
                          <p style="font-size: 18px;">
                            V - {{ informe.version }}
                          </p>
                        </div>
                        <div class="icon">
                          <i class="text-white" :class="getIcon(index)"></i>
                        </div>
                        <p class="small-box-footer">
                          {{ informe.nombre }}
                          <i class="fas fa-arrow-circle-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </section>
      </div>
    </div>
    <ReporteModal ref="ReporteModal" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import $ from "jquery";
import ReporteModal from "./ReporteModal";
//import vSelect from "vue-select";
//import { required } from "vuelidate/lib/validators";

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
      $(".ir-arriba").slideDown(600);
    } else {
      $(".ir-arriba").slideUp(600);
    }
  });
});

export default {
  name: "ReporteAutomaticoIndex",
  components: {
    Loading,
    ReporteModal,
    //vSelect,
  },
  data() {
    return {
      cargando: false,
      filtros: {},
      informes: {},
      permisosUser: this.$store.state.permissions,
      listasForms: {},
      icons: [
        "fa fa-list fa-3x",
        "fas fa-file-alt fa-3x",
        "fab fa-buffer fa-3x",
        "fas fa-clipboard-list fa-3x",
        "fas fa-file-invoice fa-3x",
        "fas fa-file-medical-alt fa-3x",
        "fas fa-scroll fa-3x",
        "fas fa-file-signature fa-3x",
        "far fa-file-excel fa-3x",
        "fas fa-file-contract fa-3x",
      ],
      colors: [
        "#3498db", // Azul claro
        "#e74c3c", // Rojo coral
        "#2ecc71", // Verde esmeralda
        "#9b59b6", // Púrpura
        "#f39c12", // Naranja
        "#1abc9c", // Verde turquesa
        "#e67e22", // Naranja más oscuro
        "#34495e", // Gris azulado
        "#d35400", // Naranja oscuro
        "#8e44ad", // Violeta oscuro
      ],
    };
  },
  validations: {},
  methods: {
    getIndex() {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/configuracionInformes/indexInformeAut", {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          //this.informes = response.data;
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].nombrePermi =
              "hidrocarburos.informeAutomatico." + response.data[i].permiso;
          }

          this.informes = response.data.filter((informe) =>
            this.permisosUser.some(
              (permiso) => informe.nombrePermi === permiso.name
            )
          );
        });
    },

    getDataModal(informe) {
      this.$refs.ReporteModal.llenarModal(informe);
    },

    getIcon(index) {
      //const randomIndex = Math.floor(Math.random() * this.icons.length);
      const iconIndex = index % this.icons.length;
      return this.icons[iconIndex];
    },

    getColor(index) {
      const colorIndex = index % this.colors.length;
      return this.colors[colorIndex];
    },

    irArriba() {
      $("body,html").animate({ scrollTop: "0px" }, 1000);
    },
  },
  async mounted() {
    await this.getIndex();
  },
};
</script>
<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>
